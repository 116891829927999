import React, { useState, useRef, useEffect } from "react";
import CommanDecobarSheetComponent from "../commanDecoderSheetComponent";
import images from "../images/images";
import "./incomeSheetComponent.scss";
import Aries from "../../locale/en-Us.json";
import ButtonGroupFooter from "../buttonGroupFooter";
import { Navigate, useNavigate } from "react-router-dom";
import NotificationComponent from "../notifcationComponent";
import { setNotification } from "../../redux/reducers";
import _ from "lodash";
import { notificationTypes } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { ExportModal } from "../exportModalComponent";
import API from "../../utils/api/api";
import { downloadBase64File } from "../../utils/utils";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
const IncomeSheetComponent = ({
  label,
  backStep,
  nextStep,
  companyName,
  decoderSheet,
  cikNumber,
  updateDecoderSheetData,
  editAllowed,
  financialYear,
}) => {
  // json display bottom buttons
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openExportModal, setOpenExportModal] = useState(false);
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);
  const [versions, setVersions] = useState([_.cloneDeep(decoderSheet)]);
  const [currentVersionIndex, setCurrentVersionIndex] = useState(0);
  const [decoderSheetData, setDecoderSheetData] = useState(
    versions[currentVersionIndex]
  );
  const notificationComponentRef = useRef(null);

  const getUpdatedIncomeSheetData = (updatedData) => {
    const fixIds = (node, parentId = "") => {
      if (parentId) {
        const correctId = `${parentId}.${node.id.split(".").pop()}`;
        node.id = correctId;
      }
      if (node.children) {
        node.children.forEach((child, index) => {
          child.id = `${node.id}.${index + 1}`;
          fixIds(child, node.id);
        });
      }
    };
    const dataCopy = JSON.parse(JSON.stringify(updatedData));
    // Fix IDs in the copied data
    dataCopy.forEach((item) => fixIds(item));
    const updatedDecoderSheet = {
      ...decoderSheetData,
      incomeStatementSheet: {
        ...decoderSheetData.incomeStatementSheet,
        sheet: {
          ...decoderSheetData.incomeStatementSheet.sheet,
          rows: updatedData,
        },
      },
    };
    const newVersions = [
      ...versions.slice(0, currentVersionIndex + 1),
      updatedDecoderSheet,
    ];
    setVersions(newVersions);
    setCurrentVersionIndex(newVersions.length - 1);
    setDecoderSheetData(newVersions[newVersions.length - 1]);
    updateDecoderSheetData(newVersions[newVersions.length - 1]);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.code === "KeyZ") {
        e.preventDefault();
        handleUndo();
      } else if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.code === "KeyZ") {
        e.preventDefault();
        handleRedo();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [decoderSheetData]);

  const exportDecoderSheet = async () => {
    const url = "v1/decoder/" + cikNumber.projectId + "/period/download";
    const response = await API({
      method: "POST",
      path: url,
      data: {
        ...versions[currentVersionIndex],
        type: "INCOME_STATEMENT",
      },
      dispatch,
    });

    const file = downloadBase64File(response?.data);
    onClose();
    if (file) {
      dispatch(
        setNotification({
          type: notificationTypes.SUCCESS,
          message: "File Downloaded Successfully",
          placement: "bottom",
        })
      );
    }
  };

  const handleUndo = (e) => {
    if (currentVersionIndex > 0) {
      setCurrentVersionIndex(currentVersionIndex - 1);
      updateDecoderSheetData(versions[currentVersionIndex - 1]);
      setDecoderSheetData(versions[currentVersionIndex - 1]);
    }
  };

  useEffect(() => {
    setDecoderSheetData(versions[currentVersionIndex]);
  }, [currentVersionIndex]);

  const handleRedo = () => {
    if (currentVersionIndex < versions.length - 1) {
      setCurrentVersionIndex(currentVersionIndex + 1);
      updateDecoderSheetData(versions[currentVersionIndex + 1]);
      setDecoderSheetData(versions[currentVersionIndex + 1]);
    }
  };
  const onClose = () => {
    setOpenExportModal(false);
  };
  const handleOpenExportModal = () => {
    setOpenExportModal(true);
  };
  const buttonGroup = {
    left: [
      {
        text: Aries.BUTTON_GLOBAL_SAVE_DRAFT,
        className: "secondary-buttons",
        fn: () =>
          saveDecoderSheet(versions[currentVersionIndex], "DRAFTED", editAllowed),
        imageLink: images["document-text.svg"],
        disabled: !editAllowed,
      },
      {
        text: Aries.BUTTON_GLOBAL_EXPORT,
        className: "secondary-buttons",
        fn: handleOpenExportModal,
        imageLink: images["document-text.svg"],
      },
    ],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        name: "back",
        className: "back-button",
        imageLink: images["arrow-left.svg"],
        fn: backStep,
      },
      {
        text: Aries.BUTTON_GLOBAL_FINISH,
        className: "primary-button",
        rightImageLink: images["arrow-right.svg"],
        fn: () =>
          saveDecoderSheet(versions[currentVersionIndex], "DECODED", editAllowed),
        disabled: isLoadingSpin,
      },
    ],
  };
  const saveDecoderSheet = async (decoderSheetData, status, editAllowed) => {
    try {
      if (!editAllowed) {
        backStep("refreshTrackApi");
        return;
      }
      setIsLoadingSpin(true);
      const url = `v1/decoder/${cikNumber.projectId}/decode${
        financialYear ? `?financialYear=${financialYear}` : ""
      }`;
      
      const result = await API({
        method: "POST",
        path: url,
        data: {
          ...decoderSheetData,
          jsonStatus: status,
          type: "INCOME_STATEMENT",
        },
        dispatch,
      });
      if (result.data) {
        setIsLoadingSpin(false);
        backStep("refreshTrackApi");
        if (status === "DECODED") {
          dispatch(
            setNotification({
              type: notificationTypes.SUCCESS,
              message: "Decoded Successfully",
              placement: "bottom",
            })
          );
          return;
        }
        dispatch(
          setNotification({
            type: notificationTypes.SUCCESS,
            message: "Saved Draft Successfully",
            placement: "bottom",
          })
        );
      } else {
        dispatch(
          setNotification({
            type: notificationTypes.ERROR,
            placement: "bottom",
          })
        );
        setIsLoadingSpin(false);
      }
    } catch (error) {
      setIsLoadingSpin(false);
    }
  };

  const jsonData = {
    table: {
      columns: [
        {
          title: decoderSheetData.incomeStatementSheet.mappedKey,
          dataIndex: "mappedKey",
          key: "mappedKey",
          editable: true,
          width: 400,
        },
        {
          title: decoderSheetData.incomeStatementSheet.reportKey,
          dataIndex: "reportKey",
          key: "reportKey",
          editable: true,
          width: 400,
        },
        {
          title: (
            <div className="sheet-date1">
              <div className="sheet-date-duration1">
                {decoderSheetData?.incomeStatementSheet?.sheet.duration1}
              </div>
              {decoderSheetData?.incomeStatementSheet?.sheet.date1}
            </div>
          ),
          dataIndex: "date1",
          key: "date1",
          editable: true,
          width: 100,
          colSpan: 1,
        },
        {
          title: decoderSheetData.incomeStatementSheet.sheet.date2,
          dataIndex: "date2",
          key: "date2",
          editable: true,
          width: 100,
        },
        {
          title: (
            <div className="sheet-date1">
              <div className="sheet-date-duration2">
                {decoderSheetData?.incomeStatementSheet?.sheet.duration2}
              </div>
              {decoderSheetData?.incomeStatementSheet?.sheet.date3}
            </div>
          ),
          dataIndex: "date3",
          key: "date3",
          editable: true,
          width: 100,
          hidden: !decoderSheetData.incomeStatementSheet.sheet.date3,
        },
        {
          title: decoderSheetData.incomeStatementSheet.sheet.date4,
          dataIndex: "date4",
          key: "date4",
          editable: true,
          width: 100,
          hidden: !decoderSheetData.incomeStatementSheet.sheet.date4,
        },
        {
          title: decoderSheetData.incomeStatementSheet.comment,
          dataIndex: "comment",
          key: "comment",
          width: 100,
        },
      ],

      dataSource: decoderSheetData?.incomeStatementSheet?.sheet?.rows?.map(
        (row) => ({
          ...row,
          key: row.id,
        })
      ),
    },
  };

  return (
    <div className="balance-container">
      <Spin
        spinning={isLoadingSpin}
        indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
        fullscreen
        className="fullscreen-loader"
      />{" "}
      <div className="wrapper-label">
        <img
          className="user-icon"
          src={images["aistra-vaa-avatar-1.svg"]}
          loading="lazy"
          alt="User icon"
        />
        <div className="label" dangerouslySetInnerHTML={{ __html: label }}></div>
      </div>
      <div
        className="heading-wrapper"
        style={{
          marginBottom:
            decoderSheetData?.incomeStatementSheet?.sheet?.duration1 ||
            decoderSheetData?.incomeStatementSheet?.sheet?.duration2
              ? "30px"
              : "",
        }}
      >
        <div className="left-heading">Income Sheet</div>
        <div className="right-heading-group">
          <div className="right-heading">
            Company: <span className="heading-value">{companyName}</span>
          </div>
          <div className="right-heading">
            Document Type:{" "}
            <span className="heading-value">{decoderSheet.documentType}</span>
          </div>
          <div className="right-heading">
            Statement Type: <span className="heading-value">Income Sheet</span>
          </div>
          {decoderSheetData?.incomeStatementSheet?.sheet?.unit && (
            <div className="right-heading">
              Unit:{" "}
              <span className="heading-value">
                {decoderSheetData?.incomeStatementSheet?.sheet?.unit}
              </span>
            </div>
          )}
        </div>
      </div>
      <CommanDecobarSheetComponent
        sheetTableData={jsonData}
        getUpdatedSheetData={getUpdatedIncomeSheetData}
        decoderSheetname={"Income Statement"}
        optionMap={decoderSheet?.optionsMap}
        editAllowed={editAllowed}
      />
      <ButtonGroupFooter
        className="fixed-button-group"
        buttonGroupObject={buttonGroup}
      />
      <ExportModal
        visible={openExportModal}
        onClose={onClose}
        handleDownloadExcel={() => exportDecoderSheet()}
      />
    </div>
  );
};
export default IncomeSheetComponent;
